<template>
    <div class="video_echart">
        <detailTabCopy style="width: 100%;" ref="detailTab" :hotWordData="hotWordData"></detailTabCopy>
    </div>
</template>

<script>
import detailTabCopy from '@/components/mediumModule/detailTabCopy'
 import { newAccountVideoSummary, newAccountRefreshVideoDetail, newAccountRefreshVideoGoods } from '@/utils/apis.js'
export default {
    data() {
        return {
            hotWordData: [
                {
                    count: 5,
                    keyword: "笑容",
                    rate: 20 
                }, {
                    count: 5,
                    keyword: "转移",
                    rate: 16 
                }, {
                    count: 4,
                    keyword: "出事",
                    rate: 12 
                }, {
                    count: 3,
                    keyword: "猴王",
                    rate:12
                }, {
                    count: 3,
                    keyword: "笑声",
                    rate: 12
                }, {
                    count: 3,
                    keyword: "摔下来",
                    rate: 8 
                }, {
                    count: 2,
                    keyword: "结局",
                    rate: 8
                },
                {
                    count: 2,
                    keyword: "凭空",
                    rate:4
                },
                {
                    count: 1,
                    keyword: "口子",
                    rate: 4
                },
                {
                    count: 1,
                    keyword: "呼呼",
                    rate: 4 
                },
            ]
        }
    },
    components: {
        detailTabCopy
    },
    mounted() {
        // this.getInfo()
        // console.log(JSON.parse(this.$route.query.options).aweme_id );
        
    },
    methods: {
        getInfo() {
            let params = {
                aweme_id: 'WMT_C4s4pLxNiG30nygJcbA3LcT-98I0'||JSON.parse(this.$route.query.options).aweme_id ,
            }
            newAccountVideoSummary(params).then(res => {
                if (res.code === 200) {
                    this.videoInfo = res.data.aweme;
                    this.hotWordData = this.videoInfo.hot_words_info;
                } else {
                    this.$message.warning(res.msg)
                }
            }).catch(err => { })
        },
    },
}
</script>
<style lang='scss' scoped>
.video_echart{
    width: 100%;

   
   
}

</style>