<template>
    <div class="contain_box">
        <div style="padding: 0 20px 20px">
            <div class="big-title">视频评论信息</div>
            <div class="no-data" v-show="!showHotWordECharts">暂无数据</div>
<!--            <div v-show="showHotWordECharts">-->
            <div class="data-box" id="hotWordDataCopy"></div>
<!--            </div>-->
        </div>

        <!-- <div class="big-title" style="padding: 0 20px">所有评论 （{{comment_count}}）</div>
        <el-scrollbar class="comment-box">
            <div class="comment-list" v-if="comment_lists.length > 0">
                <div class="comment-item" v-for="(commentItem, commentIndex) in comment_lists" :key="`comment_lists_${commentIndex}`">
                    <div class="left">
                        <i class="iconfont">&#xe85c;</i>
                        <div class="text">{{commentItem.digg_count}}</div>
                    </div>
                    <div class="center">{{commentItem.text}}</div>
                    <el-button type="primary" plain size="medium" class="copy-text" @click="clipText(commentItem.text)">复制</el-button>
                </div>
            </div>
            <div class="no-data" v-else style="height: 100px">暂无数据</div>
        </el-scrollbar> -->
    </div>
</template>

<script>
    import { newAccountVideoComment } from '@/utils/apis.js'
    import ClipboardJS from "clipboard";
    export default {
        data() {
            return {
                // hotWordData: null,
                hotWordView: null,
                comment_lists: [],
                comment_count: 0,
                showHotWordECharts: true,
            }
        },
        props: {
          hotWordData: {
            type: Array,
            default() {
              return []
            }
          }
        },
        mounted() {
            this.getInfo()

        },
        watch: {
          hotWordData: {
            handler(newVal, oldVal) {
              if(newVal.length <= 0) {
                this.showHotWordECharts = false
              }else {
                let hotData = {
                  xData: [],
                  yData: []
                }
                this.showHotWordECharts = true;
                for (let i = 0; i < newVal.length; i++) {
                    hotData.xData.push(newVal[i].keyword);
                    hotData.yData.push(newVal[i].rate);
                }
                this.$nextTick(() => {
                    this.hotWordView = this.$echarts.init(document.getElementById('hotWordDataCopy'))
                    let resizeTimer = null;
                    window.onresize = () => {
                        clearTimeout(resizeTimer);
                        resizeTimer = setTimeout(() => {
                            this.hotWordView.resize();
                        }, 100)
                    }
                    this.getHotWordData(hotData.xData, hotData.yData)
                })
              }
            },
            immediate: true,
            deep: true
          }
        },
        methods: {
            // 获取详情
            getInfo() {

                let params = {
                    aweme_id: this.$route.query.aweme_id,
                }
                newAccountVideoComment(params).then(res => {
                    if (res.code === 200) {
                        // let hotData = {
                        //     xData: [],
                        //     yData: []
                        // }
                        
                        // if (res.data.list.length === 0) {
                        //     this.showHotWordECharts = false
                        // } else {

                        //     this.showHotWordECharts = true

                        //     for (let i = 0; i < res.data.hot_words_info.length; i++) {
                        //         // hotData.xData.push(res.data.hot_words_info[i].keyword);
                        //         // hotData.yData.push(res.data.hot_words_info[i].rate);
                        //     }
                        //     this.$nextTick(() => {
                        //         this.hotWordData = this.$echarts.init(document.getElementById('hotWordData'))
                        //         let resizeTimer = null;
                        //         window.onresize = () => {
                        //             clearTimeout(resizeTimer);
                        //             resizeTimer = setTimeout(() => {
                        //                 this.hotWordData.resize();
                        //             }, 100)
                        //         }
                        //         this.getHotWordData(hotData.xData, hotData.yData)
                        //     })
                        // }
                        let data = res.data;
                        let list = data.list;
                        this.comment_lists = list;
                        // this.comment_count = res.data.comment_count
                        this.comment_count = data.total;
                    } else {
                        this.$message.warning(res.msg)
                    }
                }).catch(err => {})
            },
            // 复制
            clipText(val) {
                let clipboard = new ClipboardJS('.copy-text', {
                    text(elem) {
                        return val
                    }
                })
                clipboard.on('success', (e) => {
                    this.$message({
                        type: 'success',
                        message: '复制成功',
                        duration: 1000,
                        onClose: () => {
                            clipboard.destroy()
                        }
                    })
                })
            },

            // 年龄分布
            getHotWordData(xData, yData){
                this.hotWordView.setOption({
                    tooltip: {
                        formatter: '{b}：{c}%',
                    },
                    xAxis: {
                        type: 'category',
                        data: xData
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            show: true,
                            formatter: '{value} %'
                        },
                    },
                    grid: {
                        top: 20,
                        left: 40,
                        right: 0,
                        bottom: 40,
                    },
                    series: [{
                        name: '年龄',
                        type: 'bar',
                        barWidth: '30px',
                        data: yData,
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{c}%'
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                    {
                                        offset: 0,
                                        color: '#34aa7f', // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#46dbab', // 100% 处的颜色
                                    }
                                ], false)
                            },
                        },
                    }]
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .contain_box {
        height: 100%;
    }
    .big-title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    .data-box {
        margin-top: 10px;
        width: calc(100vw - 680px);
        height: 300px;
    }
    .comment-box {
        margin-bottom: 20px;
        ::v-deep & > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .comment-list {
            padding: 0 20px;
            max-height: 400px;
            display: flex;
            flex-wrap: wrap;
            /*align-items: flex-start;*/
            .comment-item {
                width: calc(50% - 10px);
                display: flex;
                align-items: center;
                background: #f1f0fe;
                padding: 20px;
                box-sizing: border-box;
                margin-bottom: 10px;
                &:nth-of-type(even) {
                    margin-left: 20px;
                }
                .left {
                    text-align: center;
                    .iconfont {
                        color: #e8828b;
                        font-size: 30px;
                    }
                    .text {
                        font-size: 16px;
                    }
                }
                .center {
                    margin: 0 10px 0 20px;
                    width: 1%;
                    flex: 1;
                    font-size: 16px;
                }
            }
        }
    }

    .no-data {
        height: 20px;
        margin: 20px 0;
        color: #999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>