<template>
    <div class="videoHeatBox">
        <div class="videoHeatHeader" v-if="videoList.video_goods">
            <img class="headerImg" :src="videoList.video_goods.image" alt="">
            <div class="headerCountent" v-if="videoList.video_goods">
                <p><a :href="videoList.video_goods.url" target="_blank">{{videoList.video_goods.title}}</a></p>
                <p>
                    <span><b>￥</b>{{videoList.video_goods.final_price}}</span>
                    <span>原价：￥{{videoList.video_goods.max_price}}</span>
                </p>
                <p>
                    <span>抖音浏览量:{{videoList.video_goods.pv_count}}</span>
                    <span>全网销量:{{videoList.video_goods.order_count}}件</span>
                    <span>佣金:{{videoList.video_goods.pv_count[0] ? videoList.video_goods.pv_count[0] : 2}}%</span>
                </p>
            </div>
        </div>
        <div v-else style="margin-left: 20px!important;">暂无结果</div>
        <div class="correlationVideo" v-if="videoList.video_goods">
            相关商品推广视频
        </div>
        <div class="rongQi" v-if="goodList">
        <div class="correlationVideoItem" v-for="item in videoList.goods_video_list" :key="item.aweme_id">
                <img style="cursor:pointer" @click="toCorrelation(item.aweme_info.aweme_id)" class="correlationImg" :src="item.aweme_info.aweme_cover || ''" alt="">
            <div class="correlationTitle">
                <p style="cursor:pointer" @click="toCorrelation(item.aweme_info.aweme_id)">{{item.aweme_info.aweme_title}}</p>
            </div>
            <div class="correlationUser">
                <img style="width: 40px;height: 40px;border-radius: 50%" :src="item.author_info.avatar" alt="">
                <div>
                    <p style="color: #999999">{{item.author_info.nickname}}</p>
                    <p>{{item.aweme_info.aweme_create_time}}</p>
                </div>
            </div>
            <div class="correlationRight">
                <i class="iconfont">&#xe62a;</i>
                <span>{{item.aweme_info.digg_count}}</span>
                <i style="margin-left: 20px" class="iconfont">&#xe6e7;</i>
                <span>{{item.aweme_info.share_count}}</span>
                <i style="margin-left: 20px" class="iconfont">&#xe664;</i>
                <span>{{item.aweme_info.comment_count}}</span>
            </div>
        </div>
        </div>
        <div v-else style="margin: 20px">暂无信息</div>
    </div>
</template>
<script>
    export default {
        props:{
            videoList:{
                type:Object
            }
        },
        data(){
            return {
                goodList:[],
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        created() {
        },
        methods:{
            updateData() {
                this.goodList = this.videoList.goods_video_list;
                console.log("----", this.videoList);
                // this.created()
            },
            toCorrelation(id){
                console.log(69,id)
                this.$emit('func',id)
            }
        }
    }
</script>
<style scoped lang="scss">
    .videoHeatBox{
        display: flex;
        flex-direction: column;
        height: 100%;
        /*overflow: scroll;*/
        .videoHeatHeader{
            border-bottom: 1px solid #EAEAEA;
            margin-left: 25px;
            display: flex;
            .headerImg{
                width: 110px;
                height: 158px;
                margin-bottom: 24px;
            }
            .headerCountent{
                margin-left: 23px;
                p{
                    line-height: 1;
                    margin: 0;
                }
                p:nth-child(1){
                    a{
                        color: #333333;
                        font-size: 18px;
                        font-weight: bold;
                    }
                    margin-top: 10px;
                }
                p:nth-child(2){
                    margin-top: 23px;
                    span:nth-child(1){
                        font-size: 32px;
                        color: #FF0000;
                        b{
                            font-size: 16px;
                        }
                    }
                    span:nth-child(2){
                        margin-left: 21px;
                        font-size: 16px;
                        color: #666666;
                        text-decoration:line-through;
                    }
                }
                p:nth-child(3){
                    margin-top: 23px;
                    font-size: 14px;
                    color: #999999;
                    span:nth-child(2), span:nth-child(3){
                        margin-left: 24px;
                    }
                }
            }
        }
        .correlationVideo{
            height: 65px;
            margin-left: 25px;
            border-bottom: 1px solid #EAEAEA;
            line-height: 65px;
            color: #333333;
            font-size: 16px;
        }
        .rongQi{
            .correlationVideoItem{
                position: relative;
                margin-left: 25px;
                border-bottom: 1px solid #EAEAEA;
                display: flex;
                .correlationImg{
                    width: 60px;
                    height: 88px;
                    margin-top: 18px;
                    margin-bottom: 16px;
                }
                .correlationTitle{
                    width: 432px;
                    padding-top: 38px;
                    margin-left: 24px;
                    p{
                        color: #333333;
                        font-size: 16px;
                        line-height: 24px;
                        margin: 0;

                    }
                }
                .correlationUser{
                    display: flex;
                    margin-left: 24px;
                    margin-top: 46px;
                    div{
                        margin-left: 10px;
                        p{
                            line-height: 1;
                            margin: 0;
                        }
                        p:nth-child(2){
                            color: #999999;
                            margin-top: 6px;
                        }
                    }
                }
                .correlationRight{
                    /*margin-left: 9%;*/
                    position: absolute;
                    left: 76%;
                    top: 44%;
                    display: flex;
                    align-items: center;

                    line-height: 100%;
                    i{
                        font-size: 16px;
                        color: #999999;
                    }
                    span{
                        font-size: 16px;
                        color: #999999;
                        margin-left: 8px;
                    }
                }
            }
        }
    }
</style>